.body {
    background-color:#f7f8f9;
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
  }
  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 1090px;
}
  /* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1289px) {
    
     .body {
        background-color:#f7f8f9;
        height: 750vh;
      
       }
      
    
  }
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1289px) and (orientation: landscape) {
     
     .body {
        background-color:#f7f8f9;
        
        height: 750vh;
        
      }
      
  
  }
  




  

  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) {
    
    .CA{
    grid-template-columns: 245px  !important;
    justify-content: center !important;
   }
        
 .body{
    
    background-color:#f7f8f9;
    height: 100vh;
    position:fixed !important;
 }
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    .CA{
        grid-template-columns: 275px !important;
        justify-content: center !important;
       }
       
 .body{
    
    background-color:#f7f8f9;
    height: 100vh;
    position:fixed !important;
 }

  }
