@media only screen and (min-width: 480px) {
    /* For mobile */
    .card-activation {
        width: 100%;
    }
}

@media only screen and (min-width: 992px) {
    /* For Desktop */
    .card-activation {
        width: 50%;
    }
}

@media only screen and (min-width: 768px) {
    /* For Tablet */
    .card-activation {
        width: 50%;
    }
}

