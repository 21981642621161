
.mainFeedback {
 
  height: 100vh;
  background:#f7f8f9;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background:#f7f8f9;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


/* Small screens - MOBILE */
@media all and (max-device-width: 991px) and (orientation:portrait){
    .elementor-icon-box-wrapper {

        height: 150px!important;

    }
}

.elementor-icon-box-wrapper {
   
    height: 370px;
  
}
.elementor-icon-box-description{
    font-style: italic;
    font-weight: 350;
    line-height: 1.5;
    color: #000 !important;
    font-size: 18px;
}
.item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    -webkit-transform: translateZ(0);
    user-select: none;
}
.aelementor-testimonial-item{
    margin-top: 30px;
    padding: 30px;
    border: 1px solid #e5e5e5;
    padding: 15px;
    margin-top: 15px;
    border-radius: 0 40px 0 40px;
    
    display: flex;
    flex-direction: column;
}
.aitem-box{
    padding: 30px;
    margin-top: -60px;
    background-color: #fff;
    padding: 15px;
    margin-top: -30px;
    border-radius: 0 40px 0 40px;
    display: flex;
    flex: 1 0 auto;
    margin-right: auto;
    margin-left: auto;
    flex-direction: column;
    max-width: 100%;
    transition: all .3s ease;
}
.aelementor-testimonial-content{
    font-size: 1.125rem;
    
    font-size: 16px;
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 28px;
    transition: all .2s ease;
    flex-grow: 1;
}
 .aelementor-testimonial-image {
    line-height: 0;
    margin-bottom: 1rem;
}