.give-grid div.give-grid__item .give-card__text {
    line-height: 1.2em;
    margin-bottom: 10px;
    margin-top: 15PX;
} 

 /* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1289px) {
    
    
.give-grid__item{
    width: 256px;
     margin-left:0px;
      margin-right:10px;
       height:690px;
}

     
   
 }
 
 /* 
   ##Device = Tablets, Ipads (landscape)
   ##Screen = B/w 768px to 1024px
 */
 
 @media (min-width: 768px) and (max-width: 1289px) and (orientation: landscape) {
      
.give-grid__item{
    width: 256px;
     margin-left:0px;
      margin-right:10px;
       height:690px;
    }

    
     
 
 }
 
 




  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) {
    
     
.give-grid__item{
    width: 250px  !important;
    margin-left:auto !important;
    margin-right:auto !important;
}
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
     
.give-grid__item{
    width: 250px  !important; 
    margin-left:auto !important;
    margin-right:auto !important;
  }
  
}
  