.body {
  background-color:#f7f8f9;
  height: 100vh;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
}
.sou{
    margin: 0px 20px 0px 0px;
    --e-column-margin-right: 20px;
    --e-column-margin-left: 0px;
}
.elementor-widget-wrap>.elementor-element {
    width: 100%;
}
.elementor-widget {
    position: relative;
}
.elementor *, .elementor :after, .elementor :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
*, :after, :before {
    box-sizing: inherit;
}
user agent stylesheet
div {
    display: block;
}
.elementor {
    -webkit-hyphens: manual;
    -ms-hyphens: manual;
    hyphens: manual;
}
#page {
    position: relative;
    word-wrap: break-word;
    overflow: hidden;
}
.nd_options_customizer_fonts, .nd_options_customizer_fonts .nd_options_color_grey, .nd_options_customizer_fonts p, .nd_options_customizer_fonts a, .nd_options_customizer_fonts select, .nd_options_customizer_fonts textarea, .nd_options_customizer_fonts label, .nd_options_customizer_fonts input {
    color: #a3a3a3;
}
.nd_options_customizer_fonts, .nd_options_customizer_fonts .nd_options_second_font, .nd_options_customizer_fonts p, .nd_options_customizer_fonts a, .nd_options_customizer_fonts select, .nd_options_customizer_fonts textarea, .nd_options_customizer_fonts label, .nd_options_customizer_fonts input, #start_nicdark_framework {
    font-family: 'Poppins', sans-serif;
}
.elementor-kit-138 {
    --e-global-color-primary: #9000FF;
    --e-global-color-secondary: #E6D7F1;
    --e-global-color-text: #707070;
    --e-global-color-accent: #000000;
    --e-global-color-9c8f361: #FFFFFF;
    --e-global-color-ff82fc7: #E5E5E5;
}
body, input, button, button[type="submit"], select, textarea {
    font-family: "Barlow", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-weight: 400;
    color: #717171;
}
body, button, input, select, textarea {
    color: #707070;
    font-family: Barlow, sans-serif;
}
body {
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
    line-height: 1.5;
}
:root {
    --page-title-display: block;
}
html {
    font-size: 16px;
}
html {
    font-size: 16px;
}
html {
    font-family: Barlow, sans-serif;
    line-height: 1.875;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}
.elementor *, .elementor :after, .elementor :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
*:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
*, :after, :before {
    box-sizing: inherit;
}
.elementor *, .elementor :after, .elementor :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
*:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
*, :after, :before {
    box-sizing: inherit;
}
.elementor-icon-box-wrapper{
align-items: center;
-webkit-box-align: center;
display: flex;
flex-direction: row;
height: 143px;


}

.marg{
    margin: 0px 20px 0px 0px;
}



/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 986px) {
    .stat{
        background-color: #f7f8f9;
        margin: 10px;
     }
     .body {
        background-color:#f7f8f9;
        height: 100vh;
      }
      .marg {
            margin: 0px auto;
      }
    
  }
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 986px) and (orientation: landscape) {
    .stat{
       background-color: #f7f8f9;
        margin: 10px;
        
     }
     .body {
        background-color:#f7f8f9;
        height: 100vh
      }
      .marg {
        margin: 0px auto;
  }
  }
  

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 987px) and (max-width: 1028px) {
    .stat{
        background-color: #f7f8f9;
        margin: 3px;
     }
     .body {
        background-color:#f7f8f9;
        height: 100vh;
      }
      .marg {
            margin: 0px auto;
      }
    
  }
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 987px) and (max-width: 1028px) and (orientation: landscape) {
    .stat{
       background-color: #f7f8f9;
        margin: 3px;
        
     }
     .body {
        background-color:#f7f8f9;
        height: 100vh
      }
      .marg {
        margin: 0px auto;
  }
  }




  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) {
    
    .stat{
      background-color: #f7f8f9;
        margin: 10px;
    }
    .body {
        background-color:#f7f8f9;
        height: 100vh;
        position:relative !important;
      }
      .marg {
        margin: 0px auto;
  }
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    .stat{
       background-color: #f7f8f9;
        margin: 10px;
     }
     .body {
        background-color:#f7f8f9;
        height: 100vh;
        position:relative !important;
      }
      .marg {
        margin: 0px auto;
  }
  }
  