/* Paysage */
@media all and (orientation:landscape) {

    .text_etape_progress_bar_style {
        display:inline;
        padding-top: 7px;
        font-size: 90%;
    }

    .number_etape_progress_bar_style {
        display:inline;
        width:20px;
    }

    .progresso-active-mobile{
        display: none;
    }

    .item {
        width: 100%;
        background-color: darkgray;
        margin-right: 12px;
        height: 7px;
        border-radius: 10px;
    }

    .black-common {
        background-color:black;
    }

    .progresso {
        top: 20px;
        width: 100%;
        margin-right: 12px;
        font-family: Arial;
    }

    .progresso-active {
        top: 20px;
        width: 100%;
        margin-right: 12px;
        font-family: Arial;
    }
}

/* Portrait */
@media all and (orientation:portrait) {

    .text_etape_progress_bar_style {
        display:inline;
        padding-top: 7px;
        font-size: 90%;
    }

    .number_etape_progress_bar_style {
        display:inline;
        width:20px;
    }

    .progresso-active-mobile{
        display: none;
    }

    .item {
        width: 100%;
        background-color: darkgray;
        margin-right: 12px;
        height: 7px;
        border-radius: 10px;
    }

    .black-common {
        background-color:black;
    }

    .progresso {
        top: 20px;
        width: 100%;
        margin-right: 12px;
        font-family: Arial;
    }

    .progresso-active {
        top: 20px;
        width: 100%;
        margin-right: 12px;
        font-family: Arial;
    }
}

/*Spécificité du mode portrait pour les écrans de moins de 992px*/
@media all and (max-device-width: 992px) and (orientation:portrait){

    .text_etape_progress_bar_style {
        display:inline;
        padding-top: 7px;
        font-size: 90%;
    }

    .progresso-active-mobile .number_etape_progress_bar_style {
        display:inline;
        width:22px;
    }

    .progresso-active-mobile{
        display: block;
        top: 20px;
        width: 100%;
        margin-right: 12px;
        font-family: Arial;
    }

    .progresso-active-mobile .item{
        width: 100%;
        background-color: darkgray;
        margin-right: 12px;
        height: 7px;
        border-radius: 10px;
    }

    .progresso-active-mobile .black-common {
        background-color:black;
    }

    .progresso, .progresso-active {
        display: none;
    }

    .step-name p{
        display: none;
    }

    .step-name-active p{
        display: block;
    }
}

/* Règle spécifique aux écrans de moins de 400px */
@media all and (max-device-width: 400px) and (orientation:portrait){
    .progresso-active-mobile .number_etape_progress_bar_style {
        display:inline;
        width:23px;
    }
}

.step-name {
    font-size: 12px;
    color: rgb(1, 1, 1);
}

.step-name-passed {
    font-size: 12px;
    color: rgb(2, 0, 0);
}

.numero {
    font-size: 30px;
}

.step-name-active {
    font-size: 12px;
    font-weight: 700;
}

.numero-active {
    color: #9000ff;
    font-size: 30px;
    font-weight: 700;
}

.progress-segment {
    display: flex;
    margin-bottom: 1em;
    width: 100%;
    margin-top: 10px;
}
