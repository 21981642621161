.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
    font-weight: bolder;
}

.divider {
    border-color: #e0e0e0;
    border-style: solid;
    border-width: 0 0 1px;
    height: 10px;
    line-height: 20px;
    text-align: center;
    overflow: visible;
}

.aucune_cagnotte_cloturees_encours_style, .aucune_cagnotte_programmees_style {
    text-align: center;
    font-size: 25px;
}

.divider span {
    background-color: #FFF;
    display: inline-block;
    padding: 0 10px;
    min-height: 20px;
    min-width: 10%;
}


.fb_style {
    border-radius: 0.9rem;
    background: #3b5998;
}

.rs_login_button_style {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.my_custom_flex {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.my_custom_flex_row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.etape_progress_bar_style {
    display: flex;
    flex-direction: row;
    border: 0px blue solid;
    height: 47.5pt;
    padding-top: 19.5px;
}

.my_custom_flex_row_2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media screen and (min-width: 768px) {
    #contribution_infos {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, auto);
        grid-gap: 20px;
    }
}

@media all and (min-device-width: 1099px) and (orientation:landscape){
    .custom_display_social_icon_footer{
        margin-right:59px !important;
    }
}

@media all and (max-device-width: 1098px){
    .custom_display_social_icon_footer{
        margin-right:29px !important;
    }
}

@media all and (max-device-width: 766px) and (orientation:portrait){
    .custom_display_social_icon_footer{
        margin-right:59px !important;
    }
}

/* Small screens - MOBILE */
@media all and (max-device-width: 766px) and (orientation:portrait){
    .custom_contribuer_btn_position{
        position:absolute;
        bottom:25px;
        width:80% !important;
    }

    .btn-contribuer-footer{
        margin-bottom: 20px;
    }

    .elementor-widget:not(:last-child){
        margin-bottom: 0px;
    }
}

@media all and (max-device-width: 639px) and (orientation:portrait){
    .my_custom_flex_center_2 {
        padding : 25px !important;
    }
}

.custom_contribuer_btn_position{
    position:absolute;
    bottom:25px;
    width:80%;
}

.my_custom_flex_center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.superscript {
    position: relative;
    top: -1.5em;
    font-size: 80%;
}

.underscript {
    position: relative;
    bottom: -0.5em;
    font-size: 80%;
}

.style_parrain_marraine {
    font-size: 15px !important;
}

.style_block_border {
    border: 1px #E6D7F1 solid;
}

.no_border_radius {
    border-radius: 0px !important;
}

.my_custom_flex_col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.my_custom_flex_center_col {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.instructions_bis {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}

.instructions > svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.show {
    display: block;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.succesmsg {
    background-color: #b6fff0;
    color: limegreen;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.line {
    display: inline-block;
}

.modal-90w {
    max-width: 90% !important;
}

.login__body {
    min-height: 100vh;
}

.login__image {
    -o-object-fit: contain;
    object-fit: contain;
}

.login__container,
.login__row {
    min-height: 100vh;
}

.login__card {
    background-color: #f6f6f6;
}

.top-ecart {
    margin-top: 150px;
}

.bottom-ecart {
    margin-bottom: 150px;
}

.margin-30 {
    margin-top: 30px;
}

.contenu-justify {
    text-align: justify;
    text-justify: inter-word;
}

.color-facebook {
    background-color: #4267B2;
}

.color-whatsapp {
    background-color: #25D366;
}

.color-default {
    background-color: #cdd5d0;
}

.mon-test {
    overflow: hidden;
    position: relative;
    left: -0.599976px;
}

.opacity-1 {
    opacity: 1;
}

.separator {
    height: 120px;
}

.submenu_dropdown_custom_style {
    width: auto;
    display: none;
    top: auto;
    left: 0px;
    margin-left: 0px;
    margin-top: 0px;
    min-width: 250px;
    max-width: 500px;
}

.valider_contribution_details_for_validation_style:hover {
    color: #000 !important;
    background-color: #E6D7F1 !important;
}

.valider_contribution_details_for_validation_style {
    color: #FFF;
    background-color: #9000ff;
}

/*body {*/
/*    background: linear-gradient(87deg, white, #e6d7f1) !important;*/
/*}*/

:root {
    --bodyColor: linear-gradient(87deg, white, #e6d7f1);
}

body.custom-background {
    background: var(--bodyColor);
}

.elementor-6100 .elementor-element.elementor-element-8debcf7:not(.elementor-motion-effects-element-type-background) {
    background-color: #f7f8f92e;
    color: black;
}

.elementor-6100 .elementor-element.elementor-element-0b4cce4:not(.elementor-motion-effects-element-type-background), .elementor-6100 .elementor-element.elementor-element-0b4cce4 > .elementor-motion-effects-container > .elementor-motion-effects-layer {
    background-color: #f7f8f92e;
}

.site-footer .wrap {
    color: #000 !important;
    background-color: unset;
}

.btn-link{
    color: #007bff;
}

.custom_card_style_1{
    border-radius: 40px;
    border: 1px #E6D7F1 solid;
}

.custom_card_style_2{
    border-radius: 40px 40px 0px 0px !important;
}

.give-submit{
    font-weight: normal !important;
}

button{
    font-weight: normal !important;
}

.nd_options_customizer_fonts h5{
    color:#000;
}

.nd_options_customizer_forms input[type="submit"]
{
    background-color: #9000ff;
    border: 1px #9000ff solid;
    border-radius: 8px;
    padding: .375rem .75rem;
}

.elementor-nav-menu ul {
    border-width: 1px !important;
    padding: 0px !important;
    min-width: 0px !important;
}

.modal-content{
    box-shadow: none;
}

.my_inline_style{
    display:inline;
}

.custom_style_msg_obligatoire{
    color:crimson !important;
    margin-bottom:5px;
    margin-top:-10px;
    font-size:70%;
}

.flexGrow {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

/* Small screens - MOBILE */
@media all and (max-width: 404px) {
    #passer_cette_etape_feedback, #retour_feedback, #envoyer_feedback, #btn_passer_etape, #btn_aller_accueil, #btn_retour_share {
        display: none;
    }

    .new_block_passer_cette_etape{
        display: flex !important;
        align-items: center;
        flex-direction: column;
    }

    .elementor-element .elementor-element-cd4d1d3 {
        margin-top: 1em;
    }

    .elementor-6100 .elementor-element.elementor-element-8debcf7 {
        padding-bottom: 5px !important;
    }

}

@media all and (max-width: 525px) {

    #retour_share_cagnotte, #passer_cette_etape_share_cagnotte, #go_homepage_share_cagnotte{
        display: none;
    }

    .new_displaying_share_cagnotte_button{
        display: flex !important;
        align-items: center;
        flex-direction: column;
    }
}

.give-goal-progress-bis{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.disabled_link {
    pointer-events: none;
}

.elementor-nav-menu--dropdown{text-transform:none !important;}

.nd_options_customizer_fonts label{
    color: #808080 !important;
}

.react-bootstrap-table-pagination-total {
    color: black;
}

.react-bootstrap-table {
    color: black;
}

.btn-deconexion {
    background-color: #e6d7f1;
    color: black;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.6;
    border-radius: 0.5rem;
}

.btn-deconexion:hover {
    background-color: #c9b7d5;
}
/*table td {*/
/*    color:#000;*/
/*}*/

/*table th, table td {*/
/*    text-align: center;*/
/*}*/

